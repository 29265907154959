/* MonitoringCarousel.css */
.scroll-box {
    height: 700px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.scrolling-content {
    width: 100%;
    height: 200%;
}

.scroll-image {
    animation: scroll 20s linear infinite;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    50% {
        /* Scroll from top to bottom */
        transform: translateY(-60%);
    }

    100% {
        /* Scroll from bottom to top */
        transform: translateY(0);
    }
}

.description {
    background-color: #edf3f8;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    /* Initially hidden */
    opacity: 1;
    transition: opacity 0.3s ease;
    /* Add a smooth transition effect */
}

/* Apply hover effect only in fullscreen mode */
.fullscreen .scroll-box .description {
    opacity: 0;
    /* Initially hidden in fullscreen mode */
}

.fullscreen .scroll-box:hover .description {
    opacity: 1;
    /* Show description on hover in fullscreen mode */
}

.splide__slide {
    margin: 0 .3rem !important;
}