.video-container #video-player {
    border-radius: 32px;
    background-color: #f9f8f9;
    box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.15);
}

.controls {
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    opacity: 0.2;
    transition: opacity 0.4s;
    background-color: #89cbbc;
}

.video-container:hover .controls {
    opacity: 1;
}

.timeline {
    flex: 1;
    display: flex;
    align-items: center;
    border: none;
}

.bar{
    background: #ccc;
    height: 4px;
    flex: 1;
}

.inner{
    background: #00b295;
    width: 0;
    height: 100%;
}