.shepherd-title{
    /* color: hsl(0deg 0% 0%) !important; */
    font-weight: bold !important;
    font-size: 18px !important;
    font-family: Poppins, sans-serif !important;
}

.shepherd-target{
    box-sizing: border-box !important;
    border-radius: 0 !important;
    /* background: white;
    border: 14px solid hsl(0, 0%, 100%) !important; */
}

.shepherd-enabled {
    border-radius: 8px !important;
}

.shepherd-element{
    font-family: Poppins, sans-serif !important;
    width: 544px !important;
    min-height: fit-content !important;
    font-size: 13px !important;
    padding: 0 !important;
    margin: 16px !important;
    color: hsl(0deg 0% 0%) !important;
}

.shepherd-content{
    font-family: Poppins, sans-serif !important;
    box-sizing: border-box !important;
    position: relative !important;
    /* background-color: #ffffff !important; */
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    width: 100% !important;
    min-height: 0 !important;
}

.shepherd-header{
    white-space: nowrap !important;
    padding: 16px 24px !important;
}

.shepherd-text{
    font-family: Poppins, sans-serif !important;
    margin-top: 0 !important;
    max-height: 500px !important;
    position: relative !important;
    overflow: hidden auto !important;
    outline-offset: -5px !important;
    padding: 16px 26px !important;
}
/*.first{*/
/*    width: auto !important;*/
/*    height: auto !important;*/
/*    word-wrap: break-word;*/
/*    background: white;*/
/*    border-radius: 8px;*/
/*    margin-left: 18px !important;*/
/*}*/

.shepherd-footer{
    font-family: Poppins, sans-serif !important;
    padding: 8px !important;
    order:0;
    flex: 0 1 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid hsl(0deg 0% 93%);
}

.shepherd-button {
    font-family: Poppins, sans-serif !important;
    background: #00B295 !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    color: white !important;
}
.shepherd-button.shepherd-button-secondary{
    background: white !important;
    color: black !important;
    border: 1px solid #E6EBEA !important;
}

/* .shepherd-has-title .shepherd-content .shepherd-header{
    background: hsl(0deg 0% 100%) !important;
} */
