.container {
    display: grid;
    grid-template-columns: 250px 3fr;
    grid-template-rows: 72px 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "Logo Header"
    "Sidebar Content";
    width: 100vw;
    height: 100vh;
}

.Content {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    z-index: 5;
    padding: 50px 60px 50px;
    min-height: 1200px;
    grid-auto-flow: row;
    grid-template-areas: "";
    grid-area: Content;
}

.Sidebar {
    grid-area: Sidebar;
    display: flex;
    position: fixed;
    z-index: 0;
    top: 70px;
    left: 0;
    align-items: flex-start;
    padding: 60px 25px;
}

.Header {
    grid-area: Header;
    max-width: 100vw;
    position: sticky;
    background-color: white;
    z-index: 10;
    top: 0;
    right: 0;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Logo {
    grid-area: Logo;
    display: flex;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    align-items: center;
    padding: 10px 25px;
}