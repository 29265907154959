
.video-js {
    border-radius: 12px;
    max-width: 500px !important;
    max-height: 352px !important;
    margin-bottom: 14px;
    background-color: #ffffff !important;
}

.video-js .vjs-dock-text {
    background: linear-gradient(180deg, rgba(255, 255, 255, .5) 25%, transparent) !important;
    height: 1% !important;
}

.video-js .vjs-dock-title, .vjs-dock-description {
    font-family: "Poppins", serif;
    font-size: 16px;
}

.mtz-download-btn {
    background: #00b295 !important;
}

.mtz-download-btn .mtz-download-btn-dropdown .mtz-download-btn-dropdown-item {
    background: #00b295 !important;
}

.mtz-download-btn .mtz-download-btn-dropdown .mtz-download-btn-dropdown-item:hover {
    background: #00b295 !important;
}

.video-js .vjs-big-play-button {
    background-color: transparent !important;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.video-js .vjs-control-bar {
    opacity: .6;
    background-color: #00b295 !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "";
    background-image: url('/images/play-rounded-button1.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    background-position: 55% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
}

video[poster] {
    object-fit: fill;
}

.vjs-poster {
    width: 100% !important;
    height: 100% !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
    .youtube-iframe {
        height: calc(100vh - 150px);
    }
}